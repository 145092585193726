import createReducer from './createReducer'
import { CREATE_SESSION, DESTROY_SESSION, SET_USUARIO } from '../actions/Types'

const initialState = {
  token: '',
  session: {
    nombre: '',
    email: '',
    permisos: 0,
    sucursal: null,
    sucursales: []
  }
}

export const session = createReducer(initialState, {
  [CREATE_SESSION]: (state, action) => ({
    token: action.token
  }),
  [SET_USUARIO]: (state, action) => ({
    ...state,
    session: { ...action.session }
  }),
  [DESTROY_SESSION]: () => ({...initialState})
})