import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGIN } from '../utils/Routes'
import { doLogout, getUsuario } from '../actions/SessionActions'

import { info } from "../config";


const Toggle = () => window.$('#navbarNav').collapse('hide');

class Header extends Component {
  componentDidMount() {
    this.props.getUsuario().catch(this.failNetwork)
  }

  failNetwork = (e) => {
    console.log(e)
    if (e.status === 401) {
      this.logout()
    } else {
      this.setState({ network: false })
    }
  }

  refresh = (e) => {
    e.preventDefault()
    window.location.reload()
  }

  logout = () => {
    this.props.doLogout().catch(console.error)
  }

  sucursales = (e) => {
    e.preventDefault()
    Toggle()
    window.$('#sucursalesModal').modal('toggle')
  }

  _active = (p) => this.props.history.location.pathname === p ? ' active' : ''

  render() {
    let usuario = this.props.usuario
    if (!usuario || usuario === null) return null
    
    let sucursal = usuario.sucursales.find(s => s.id === usuario.sucursal)
    

    return (
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark cs-primary-bg shadow-sm">
        <div className="navbar-brand cs-pointer" onClick={this.refresh}>
          <img src="assets/logo.png" alt="kamisushi"/> {sucursal.nombre}
        </div>
        <button id="toggler" className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            {/*<li className="nav-item  active">
              <a href="/" className="nav-link cs-pointer" onClick={this.sucursales}>Sucursales</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Configuracion
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item" to={DASHBOARD} onClick={Toggle}>Despachos</Link>
                <Link className="dropdown-item" to={COCINA} onClick={Toggle}>Cocina</Link>
                <Link className="dropdown-item" to={CUARTOCALIENTE} onClick={Toggle}>Cuarto Caliente</Link>
              </div>
    </li>*/}
            <li className="nav-item nav-link">
              <div><i className="fas fa-user-circle fa-lg"></i> {usuario.name} <small>({usuario.email})</small></div>
            </li>
            <li className="nav-item nav-link">
              <div>version {info.version}</div>
            </li>
            <li className="nav-item active">
              <Link to={LOGIN} onClick={this.logout}>
              <i className="fas fa-sign-out-alt fa-lg fa-2x text-white"></i>
              <span className="text-white ml-2">Salir</span></Link>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}


const mapStateToProps = (state) => ({
  usuario: state.session.session
})

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout()),
  getUsuario: () => dispatch(getUsuario())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)