import { Component } from 'react';
import { error } from "../api/Casillas";

export default class Screen extends Component {
  onChange = (e) => {
    let id = e.target.id
    let type = e.target.type
    let value = type === 'checkbox' ? e.target.checked : e.target.value

    this.setState({ [id]: value })
  }

  back = () => this.props.history.goBack()

  loading = () => this.setState({loading: !this.state.loading})

  navigate = (path) => {
    if (this.props.history) {
        this.props.history.push(path)
    }
  }

  logError = (message) => {
    error(message)
  }
}