import { version } from "../package.json";

export const info = {
  version
}

export const hosts = {
  'dev': 'http://localhost:8000',
  'cl': 'https://api.clan28.cl',
  'ur': 'https://api-ur.comandapp.cl'
}