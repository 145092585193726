import numeral from './Numeral'
import moment from './Moment'

export const MAX_NUMBER = 999999999999999

export function isEmpty(s) {
  return !s || s === null || (s.trim ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function numeric(value) {
  return numeral(value).format('0,0')
}

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

export function isEmail(email) {
  return EMAIL_REG.test(email);
}

export function now() {
  return moment().format('DD/MM/YYYY HH:mm:ss')
}

export function formatTime(date) {
  return moment(date).format('HH:mm:ss')
}

export function reduceProductos(t, p) {
  if (p.subproducts) {
    t += p.subproducts.reduce(reduceProductos, 0)
  }
  return t + (p.price * (p.quantity ? p.quantity : 1))
} 

export function is_develop() {
  return process.env.NODE_ENV === 'development'
}