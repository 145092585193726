import React from 'react';
import { connect } from 'react-redux'
import Screen from './Screen'
import Loading from '../components/Loading'
import { obtenerPedido, pagar, entregarOrden } from '../api'
import { numeric, reduceProductos } from '../utils'
import Payment from "../utils/Payment";


class DetallePedido extends Screen {
  state = {
    _id: '',
    user: {
      phone: '',
      address: '',
      name: ''
    },
    comment: '',
    payment: 0,
    products: [],
    deliveryCost: 0,
    deliveryStatus: '',
    loading: true
  }

  componentDidMount() {
    this.cargarPedido()
  }

  cargarPedido = () => obtenerPedido(this.props.match.params.id).then(res => {
    if (res.status !== 'success') {
      return Promise.reject(res)
    }
    if (res.data !== null) {
      this.setState({ ...res.data })
    }
  }).catch(console.error).finally(this.loading)

  entregar = () => {
    entregarOrden(this.state._id).then(this.back).catch(this.onError).finally(this.loading)
  }

  pagar = () => {
    this.loading()
    pagar(this.state._id).catch(console.error).finally(this.cargarPedido)
  }

  render() {
    if (this.state.loading) return <Loading />
    let { pay, user, products, comment, payment, deliveryCost, deliveryStatus, loading } = this.state

    let total = products.reduce(reduceProductos, 0)
    let pago = Payment.find(v => payment === v.id)
    
    //total *= pago.recargo ? pago.recargo : 1
    total += deliveryCost

    return (
      <div>
        <div className="p-3">
          <div>
            <div className="mb-3 p-1 border-bottom">
              <i className="fal fa-phone fa-2x mr-3"></i>+56 {user.phone}
            </div>
            <div className="mb-3 p-1 border-bottom">
              <i className="fal fa-map-marker-alt fa-2x mr-3"></i>{user.address}
            </div>
            <div className="mb-3 p-1 border-bottom">
              <i className="fal fa-user fa-2x mr-3"></i>{user.name}
            </div>
            <div className="mb-3 p-1 border-bottom">
              <i className="fal fa-comment fa-2x mr-3"></i>{comment}
            </div>
            <div className="p-1">
              <i className="fal fa-credit-card fa-lg mr-3"></i>
              {pago && pago.label}
            </div>
            <div className="list-group list-group-flush">
              {products.map((p, i) => <Product key={i} {...p} />)}
            </div>
            <div className="text-right pt-2 pb-2">
              <p>Despacho: ${numeric(deliveryCost)}</p>
              <h4>Total: ${numeric(total)}</h4>
            </div>
          </div>
          {deliveryStatus !== 'DONE' && <Entregar loading={loading} onClick={this.entregar} />}
          {/*pay ? <Pagado /> :
            <button className="btn btn-success btn-lg btn-block" type="button" onClick={this.pagar}
            disabled={loading}><LoadingLabel label="Pagar" loading={loading} /></button>
          */}
          <button className="btn btn-light btn-lg btn-block" type="button" onClick={this.back}>Volver</button>
        </div>
      </div>
    )
  }
}

const Entregar = ({onClick, loading}) => (
  <button className="btn btn-primary btn-lg btn-block" type="button" onClick={onClick}
    disabled={loading}><LoadingLabel label="Entregar" loading={loading} /></button>
)

const Pagado = () => (
  <button className="btn btn-success btn-lg btn-block" type="button" disabled={true}>
    <i className="fas fa-check-circle"></i> El pedido esta pagado
  </button>
)

const Product = ({ title, detail, price, quantity, subproducts }) => (
  <div className="list-group-item">
    <div className="d-flex justify-content-between">
      <h5>{title} x {quantity}</h5>
      <h5>${numeric(price)}</h5>
    </div>
    <small className="text-muted">{detail}</small>
    {subproducts &&
    <ul>
      {subproducts.map((v, i) => (
        <li key={i}>{v.title} ${numeric(v.price)}</li>
      ))}
    </ul>}
  </div>
)

const LoadingLabel = ({ label, loading }) => loading ? (
  <i className="fas fa-circle-notch fa-spin"></i>
) : label

function tipoPago(tipo) {
  switch (tipo) {
    case 1: return <i className="fal fa-credit-card-front fa-lg"> Tarjeta</i>
    case 2: return <i className="fal fa-university fa-lg"> Transferencia</i>
    default: return <i className="fal fa-money-bill-alt fa-lg"> Efectivo</i>
  }
}

const mapStateToProps = (state) => ({
  pedidos: state.pedidos
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DetallePedido)