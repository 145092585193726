import { SET_PEDIDOS } from './Types'
import { fetchOrders } from '../api'


export function fetchPedidos(estado) {
  return (dispatch) => fetchOrders(estado).then(res => {
    if (res.status === 'success') {
      dispatch({
        type: SET_PEDIDOS,
        pedidos: res.data
      })
    } else {
      return Promise.reject(res)
    }
  })
}