import React, { Component } from "react"
import { DELIVERY, LOCAL, RESTAURANT } from "../utils/Status";
import { fetchOrders } from "../api";

const ALL = 'ALL'

export default class Cocina extends Component {
  state = {
    status: ALL,
    pedidos: []
  }

  componentDidMount() {
    fetchOrders('WORKING').then(pedidos => this.setState({ pedidos })).catch(console.error)
  }

  changeStatus = (status) => this.setState({ status })

  render() {
    return (
      <div>
        <div className="cs-botones-estado">
          <BotonEstado label="Todos" className="cs-color-todos"
            value={this.state.status} type={ALL} onClick={this.changeStatus} />
          <BotonEstado label="Retiro" className="cs-color-retiro"
            type={DELIVERY} value={this.state.status} onClick={this.changeStatus} />
          <BotonEstado label="Domicilio" className="cs-color-domicilio"
            type={LOCAL} value={this.state.status} onClick={this.changeStatus} />
          <BotonEstado label="Servir" className="cs-color-servir"
            type={RESTAURANT} value={this.state.status} onClick={this.changeStatus} />
        </div>
        <ul className="list-group list-group-flush">
          {this.state.pedidos.map(p => (
            <li className="list-group-item" key={p._id}>{p._id}</li>
          ))}
        </ul>
      </div>
    )
  }
}

const BotonEstado = ({ label, onClick, className, value, type }) => (
  <div className={"cs-pointer p-3 text-center w-100 " + className + (value === type ? ' cs-selected' : '')} onClick={() => onClick(type)}>
    <strong>{label}</strong>
  </div>
)