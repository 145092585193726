import React from 'react'

export default ({ usuario, fijarSucursal }) => usuario == null ? null : (
  <div id="sucursalesModal" className="modal" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Sucursales</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label htmlFor="exampleFormControlSelect1">Selecciona la sucursal</label>
              <select className="form-control form-control-lg" id="sucursal" >
                {usuario.sucursales.map(s => (
                  <option key={s._id}>{s.name}</option>
                ))}
              </select>
            </div>
            <button type="button" className="btn btn-block btn-lg btn-primary" onClick={fijarSucursal}>Cambiar</button>
            <button type="button" className="btn btn-block btn-lg btn-link" data-dismiss="modal">Cerrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>

) 