import React, { Component } from 'react';
import { connect } from 'react-redux'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { PRIVATE, CUARTOCALIENTE, COCINA, VER_PEDIDO } from './utils/Routes'
import { Dashboard, Login, Cocina, CuartoCaliente, DetallePedido } from './screens'
import { Header, SucursalesModal } from './components'
import { isEmpty } from './utils'
import { doLogout } from './actions/SessionActions'
import './App.css';

class App extends Component {
  state = {
    network: true
  }

  fijarSucursal = (id) =>  {
    console.log(id)
  }

  render() {
    if (!this.state.network) return <NetworkError />
    if (!this.props.authorized) return <Login />

    return (
      <Router>
        <div>
          <Route component={Header} />
          <div className="cs-body">
            <Switch>
              <Route exact path={COCINA} component={Cocina} />
              <Route exact path={CUARTOCALIENTE} component={CuartoCaliente} />
              <Route exact path={PRIVATE} component={Dashboard} />
              <Route exact path={VER_PEDIDO} component={DetallePedido} />
              <Route component={Dashboard} />
            </Switch>
          </div>
          <SucursalesModal usuario={this.props.usuario} fijarSucursal={this.fijarSucursal} />
        </div>
      </Router>
    );
  }
}

const NetworkError = () => (
  <div className="container">
    <p>Problemas de conexion con el servidor. Si el problema persiste avise al administrador.</p>
    <button className="btn btn-error btn-block" type="button" onClick={() => window.location.reload()}>Intentar Nuevamente</button>
  </div>
)

const mapStateToProps = (state) => ({
  authorized: !isEmpty(state.session.token),
  usuario: state.session.usuario
})

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
