import { get, post, del } from "./Methods";


export const sendEmail = (email) => post('/api/email', { email })
export const login = (email, codigo) => post('/api/login', { email, codigo })
export const logout = () => del('/api/v1/logout')

export const session = () => get('/api/v1/session')
export const sucursales = () => get('/sucursales')

export const fijarSucursal = (id) => post('/api/v1/user/sucursal', { id })
export const fetchOrders = () => get('/api/v1/temaki/pedidos')
export const obtenerPedido = (id) => get('/api/v1/pedidos/' + id)

export function entregarOrden(_id) {
  return post('/api/v1/temaki/order/done', {_id})
}

export function pagar(id) {
  return post('/api/v1/order/pay', { id })
}