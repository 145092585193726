import {combineReducers} from 'redux'
import { session } from './SessionReducer'
import { pedidos } from './PedidosReducer'
import createReducer from './createReducer'
import { CONFIG_SET_HOST } from '../actions/Types'
import { hosts } from '../config'


const initialConfig = {
  selected: 'cl'
}

const config = createReducer(initialConfig, {
  [CONFIG_SET_HOST]: (state, { value }) => ({
    selected: value
  })
})

export function config_host_fs(state) {
  return hosts[state.config.selected]
}

export default combineReducers({
  session, pedidos, config
})

