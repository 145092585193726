import React, { Component } from "react"

export default class CuartoCaliente extends Component {
  render() {
    return (
      <div>
        cuarto caliente
      </div>
    )
  }
}