export function error(message) {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  fetch('https://casillas-sandbox.herokuapp.com/message', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      message,
      leve: 'ERROR'
    })
  })
}