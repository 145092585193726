import React from 'react';
import { connect } from 'react-redux'

import Screen from './Screen'
import Loading from '../components/Loading'
import {formatTime, numeric, reduceProductos} from '../utils'
import { doLogout } from '../actions/SessionActions'
import { fetchPedidos } from "../actions/PedidosAction";
import { VER_PEDIDO } from "../utils/Routes";

const POR_ENTREGRAR = undefined
const ENTREGADO = 'DONE'

class Dashboard extends Screen {
  state = {
    status: POR_ENTREGRAR,
    loading: true
  }

  componentDidMount() {
    this.fetch().finally(this.loading)
    this._timer = setInterval(this.fetch, 5000)
  }

  componentWillUnmount() {
    clearInterval(this._timer)
  }

  fetch = () => this.props.fetchPedidos().catch(this.onError)

  verPedido = (id) => this.props.history.push(VER_PEDIDO.replace(':id', id))
  porEntregar = () => this.setState({status: POR_ENTREGRAR})
  entregado = () => this.setState({status: ENTREGADO})

  onError = (e) => {
    if (e.status === 401) {
      return this.props.doLogout()
    }
    this.logError(e.statusText ? e.statusText : e)
  }

  render() {
    if (this.state.loading) return <Loading />
    if (this.props.pedidos.length === 0) return <Empty />

    const orders = this.props.pedidos.filter(o => o.deliveryStatus === this.state.status)
    return (
      <div>
        <div className="d-flex">
          <button className={"btn btn-block btn-lg rounded-0 " + selected(this.state.status, POR_ENTREGRAR)} type="button"
            onClick={this.porEntregar}>Por despachar</button>
          <button className={"btn btn-block btn-lg rounded-0 " + selected(this.state.status, ENTREGADO)} type="button"
            onClick={this.entregado}>Despachadas</button>
        </div>
        <Totalizer visible={this.state.status === ENTREGADO} orders={orders} />
        <div className="list-group list-group-flush">
          {orders.map((order) => (
            <Order key={order._id} {...order} onClick={this.verPedido}/>
          ))}
        </div>
      </div>
    );
  }
}

const Totalizer = ({visible, orders}) => !visible ? null : (
  <div className="d-flex justify-content-around p-1">
    <div className="text-center">
      <label>Total costo despacho</label>
      <h5>${numeric(orders.reduce((t, o) => t + o.deliveryCost, 0))}</h5>
    </div>
    <div className="text-center">
      <label>Total productos</label>
      <h5>${numeric(orders.map(v => v.products.reduce(reduceProductos, 0)).reduce((t, v) => t + v, 0))}</h5>
    </div>
    <div className="text-center">
      <label>Total</label>
      <h5>${numeric(orders.map(v => v.products.reduce(reduceProductos, v.deliveryCost)).reduce((t, v) => t + v, 0))}</h5>
    </div>
  </div>
)

const Order = ({_id, index, datetime, deliveryCost, payment, products, user, onClick}) => (
  <div className="list-group-item cs-pointer" onClick={() => onClick(_id)}>
    <div className="d-flex flex-row justify-content-between">
      <div className="d-flex flex-column">
        <h5>{index}. {user.address}</h5>
        <small className="text-muted">{formatTime(datetime)}</small>
        <span>{user.phone} {user.name}</span>
      </div>
      <div className="text-right d-flex flex-column justify-content-between">
        <small className="text-muted">{paymentIcon(payment)}</small>
        <h5>${numeric(products.reduce(reduceProductos, deliveryCost))}</h5>
        <small className="text-muted">Despacho: ${numeric(deliveryCost)}</small>
      </div>
    </div>
  </div>
)

const Empty = () => (
  <div className="cs-empty">No tienes despachos.</div>
)

function paymentIcon(payment) {
  switch (payment) {
    case 1: return 'Tarjeta'
    case 2: return 'Transferencia'
    default: return 'Efectivo'
  }
}

function selected(current, status) {
  return current === status ? 'btn-link' : 'btn-light'
}


const mapStateToProps = (state) => ({
  pedidos: state.pedidos
})

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout()),
  fetchPedidos: () => dispatch(fetchPedidos('DELIVER'))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)