import React, {Component} from 'react'

export default class CountDownButton extends Component {
  state = {
    count: 3,
    disabled: false
  }

  onClick = (e) => {
    this.setState({disabled: true, count: 3})
    let timeout = setInterval(() => {
      this.setState({count: this.state.count - 1})
      if (this.state.count === 0) {
        clearInterval(timeout)
        this.setState({disabled: false})
      }
    }, 1000);
    this.props.onClick(e)
  }

  render() {
    return (
      <button className="btn btn-link btn-block btn-lg cs-button text-white"
       type="button"
       disabled={this.props.disabled || this.state.disabled}
       onClick={this.onClick}>{this.state.disabled ? this.state.count : this.props.children}</button>
    )
  }
}

//const Spinning = () => <i className="fas fa-circle-notch fa-spin"></i>